/* istanbul ignore file: test forms*/
import {FORMS_TEMPLATE_IDS} from '../../constants';
import {FormViewer} from '@wix/form-viewer/widget';
import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {FormProps} from '../ContactForm/ContactForm';
import {CheckoutSettingsModel} from '../../../../domain/models/checkoutSettings/CheckoutSettings.model';
import {FormValues} from '@wix/form-viewer';
import {AddressModel} from '../../../../domain/models/Address.model';
import {ApiAddress} from '@wix/ambassador-ecom-v1-checkout/types';
import {useControllerProps} from '../../Widget/ControllerContext';
import {CashierMandatoryFieldsOverrides} from '../../../../types/app.types';

export interface AddressFormProps extends FormProps {
  overrides: CashierMandatoryFieldsOverrides;
}

export const AddressForm = ({
  formRef,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  overrides,
}: AddressFormProps) => {
  const {i18n} = useTranslation();
  const {
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      overrides={{
        address: {
          subdivision: {
            ...(overrides?.state ? {hidden: false, required: true} : {}),
          },
          address_line: {
            ...(overrides?.address ? {hidden: false, required: true} : {}),
          },
          street_name: {
            ...(overrides?.street ? {hidden: false, required: true} : {}),
          },
          street_number: {
            ...(overrides?.houseNumber ? {hidden: false, required: true} : {}),
          },
          postal_code: {
            ...(overrides?.zipCode ? {hidden: false, required: true} : {}),
          },
          address_line_2: {
            hidden: !checkoutSettings.addressLine2.show,
            required: checkoutSettings.addressLine2.mandatory,
          },
        },
      }}
      formId={FORMS_TEMPLATE_IDS.ADDRESS}
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      onValidate={setFormErrors}
    />
  );
};

export const getAddressFormInitialState = (
  checkoutSettings: CheckoutSettingsModel,
  address?: AddressModel
): FormValues => {
  const subdivision = address?.subdivision?.includes('-') ? address?.subdivision?.split('-')[1] : address?.subdivision;
  return {
    address: {
      country: address?.country ?? '',
      city: address?.city ?? '',
      ...(subdivision ? {subdivision} : {}),
      ...(address?.postalCode ? {postal_code: address.postalCode} : {}),
      ...(address?.addressLine ? {address_line: address.addressLine} : {}),
      ...(address?.streetAddress?.name ? {street_name: address.streetAddress.name} : {}),
      ...(address?.streetAddress?.number ? {street_number: address.streetAddress.number} : {}),
      ...(checkoutSettings.addressLine2.show ? {address_line_2: address?.addressLine2 ?? ''} : {}),
    },
  };
};

export const getAddressFromAddressFormValues = (
  checkoutSettings: CheckoutSettingsModel,
  addressFormValues: FormValues
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ApiAddress | undefined => {
  const address = addressFormValues.address;
  if (typeof address === 'object' && !Array.isArray(address) && address !== null) {
    const streetAddress =
      address.street_name || address.street_number
        ? {
            name: (address.street_name as string) || undefined,
            number: (address.street_number as string) || undefined,
          }
        : undefined;

    return {
      country: address.country as string,
      city: address.city as string,
      ...(address.subdivision
        ? {
            subdivision: `${address.country as string}-${address.subdivision as string}`,
          }
        : {}),
      ...(address.postal_code ? {postalCode: address.postal_code as string} : {}),
      ...(address.address_line ? {addressLine: address.address_line as string} : {}),
      ...(streetAddress ? {streetAddress} : {}),
      ...(checkoutSettings.addressLine2.show && address.address_line_2
        ? {addressLine2: address.address_line_2 as string}
        : {}),
    };
  }
};
