import React, {useEffect, useRef, useState} from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {NextStepButton} from '../../../StepsManager/Components/NextStepButton/NextStepButton';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {FormError, FormValues, FormViewerHandle} from '@wix/form-viewer/widget';
import {classes} from './CustomerDetailsOpen.st.css';
import {EmailForm, getEmailFormInitialState, getEmailFromEmailFormValues} from '../../../../Form/EmailForm/EmailForm';
import {ContactForm} from '../../../../Form/ContactForm/ContactForm';
import {
  AdditionalInfoForm,
  getAdditionalInfoFormInitialState,
  getCustomFieldFromAdditionalInfoFormValues,
} from '../../../../Form/AdditionalInfoForm/AdditionalInfoForm';
import {CustomerDetailsStepDataHook} from '../CustomerDetailsStep';
import {StatesButtonStates} from 'wix-ui-tpa';
import {PickupInfoBox} from '../../../../PickupInfoBox/PickupInfoBox';
import {FormLoader} from '../../../../Form/FormLoader/FormLoader';
import {
  getContactDetailsFromContactFormValues,
  getContactFormInitialState,
} from '../../../../../../domain/utils/contactDetails.utils';

export enum CustomerDetailsDataHook {
  emailForm = 'CustomerDetails.emailForm',
  contactForm = 'CustomerDetails.contactForm',
  additionalInfoForm = 'CustomerDetails.additionalInfoForm',
}

export const CustomerDetailsOpen = () => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {submitCustomerDetails, checkout},
    checkoutSettingsStore: {checkoutSettings},
    formsStore: {areFormsLoaded},
    navigationStore: {isMember},
  } = useControllerProps();

  const emailFormViewer = useRef<FormViewerHandle>(null);
  const [emailFormValues, setEmailFormValues] = useState<FormValues>(getEmailFormInitialState(checkout.buyerInfo));
  const [emailFormErrors, setEmailFormErrors] = useState<FormError[]>([]);

  const contactFormViewer = useRef<FormViewerHandle>(null);
  const [contactFormValues, setContactFormValues] = useState<FormValues>(
    getContactFormInitialState(checkoutSettings, checkout.shippingDestination?.contact)
  );
  const [contactFormErrors, setContactFormErrors] = useState<FormError[]>([]);

  const additionalInfoFormViewer = useRef<FormViewerHandle>(null);
  const [additionalInfoFormValues, setAdditionalInfoFormValues] = useState<FormValues>(
    getAdditionalInfoFormInitialState(checkout.customField)
  );
  const [additionalInfoFormErrors, setAdditionalInfoFormErrors] = useState<FormError[]>([]);

  const formRefs = [contactFormViewer, additionalInfoFormViewer];

  if (!isMember) {
    formRefs.push(emailFormViewer);
  }

  const [buttonState, setButtonState] = useState(StatesButtonStates.IDLE);
  const [buttonDisabledState, setButtonDisabledState] = useState(!areFormsLoaded);

  useEffect(() => {
    setButtonDisabledState(!areFormsLoaded);
  }, [areFormsLoaded]);

  const validateAndSubmit = async () => {
    setButtonDisabledState(true);
    setButtonState(StatesButtonStates.IN_PROGRESS);

    const areFormsValidArr = await Promise.all(
      formRefs.filter((ref) => !!ref.current).map((ref) => ref.current!.validate())
    );

    if (!areFormsValidArr.includes(false)) {
      const customFieldValue = checkoutSettings.customField.show
        ? getCustomFieldFromAdditionalInfoFormValues(additionalInfoFormValues)
        : undefined;
      const contactDetails = getContactDetailsFromContactFormValues(
        contactFormValues,
        checkoutSettings,
        checkout.shippingDestination?.contact
      );
      const email = !isMember ? getEmailFromEmailFormValues(emailFormValues) : checkout.buyerInfo.email;
      void submitCustomerDetails({
        contactDetails,
        email,
        customFieldValue,
      });
    } else {
      setButtonDisabledState(false);
      setButtonState(StatesButtonStates.IDLE);
    }
  };

  return (
    <div data-hook={CustomerDetailsStepDataHook.open} className={classes.root}>
      {!areFormsLoaded && <FormLoader />}
      {areFormsLoaded && (
        <>
          {!isMember && (
            <div data-hook={CustomerDetailsDataHook.emailForm}>
              <EmailForm
                formRef={emailFormViewer}
                formValues={emailFormValues}
                formErrors={emailFormErrors}
                setFormValues={setEmailFormValues}
                setFormErrors={setEmailFormErrors}
              />
            </div>
          )}
          <div data-hook={CustomerDetailsDataHook.contactForm}>
            <ContactForm
              formRef={contactFormViewer}
              formValues={contactFormValues}
              formErrors={contactFormErrors}
              setFormValues={setContactFormValues}
              setFormErrors={setContactFormErrors}
            />
          </div>
          {checkoutSettings.customField.show && (
            <div data-hook={CustomerDetailsDataHook.additionalInfoForm}>
              <AdditionalInfoForm
                formRef={additionalInfoFormViewer}
                formValues={additionalInfoFormValues}
                formErrors={additionalInfoFormErrors}
                setFormValues={setAdditionalInfoFormValues}
                setFormErrors={setAdditionalInfoFormErrors}
              />
            </div>
          )}
        </>
      )}
      {checkout.selectedShippingOption?.pickupInfo && (
        <PickupInfoBox pickupInfo={checkout.selectedShippingOption.pickupInfo} />
      )}
      <NextStepButton
        onClick={() => void validateAndSubmit()}
        text={localeKeys.checkout.continue_button_label()}
        dataHook={CustomerDetailsStepDataHook.continueButton}
        disabled={buttonDisabledState}
        buttonState={buttonState}
      />
    </div>
  );
};
