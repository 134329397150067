import {FORMS_TEMPLATE_IDS} from '../../constants';
import {FormValues, FormViewer} from '@wix/form-viewer/widget';
import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {FormProps} from '../ContactForm/ContactForm';
import {BuyerInfoModel} from '../../../../domain/models/BuyerInfo.model';

export const EmailForm = ({formRef, formValues, setFormValues, formErrors, setFormErrors}: FormProps) => {
  const {i18n} = useTranslation();
  const localeKeys = useLocaleKeys();

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      overrides={{
        email: {label: localeKeys.checkout.address_information.email_label()},
      }}
      formId={FORMS_TEMPLATE_IDS.EMAIL}
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      onValidate={setFormErrors}
    />
  );
};

export const getEmailFormInitialState = (buyerInfo: BuyerInfoModel) => ({
  email: buyerInfo.email ?? '',
});

export const getEmailFromEmailFormValues = (emailFormValues: FormValues): string => emailFormValues.email as string;
